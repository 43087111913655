<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="$emit('close')" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-40" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-200 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all space-y-8">
              <div :class="payNowSelected ? 'opacity-25' : null" v-if="cartIsFinanceable">
                <h2>{{ copy.paymentTitle }}</h2>

                <p>{{ copy.paymentCopy }}</p>

                <div class="grid grid-cols-2 grid-gap">
                  <submit-button @click="setPayByFinance" class="outline-0 !border !px-2 !text-center text-base" :defaultText="copy.financeCTA" :loading="payByFinanceLoading" :disabled="payNowSelected || payByCardLoading" :buttonClass="'button-border'" />
                  <submit-button @click="setPayByCard" :defaultText="copy.cardCTA" :loading="payByCardLoading" :disabled="payNowSelected || payByFinanceLoading" />
                </div>
              </div>

              <div v-if="payNowSelected">
                <h2>{{ copy.deliveryTitle }}</h2>

                <p>{{ copy.deliveryCopy }}</p>

                <div class="mb-4 text-red" v-if="errorMessage">
              <span class="font-bold">{{ errorMessage }}</span>
              <div class="" v-if="errorMessageDetail">{{ errorMessageDetail }}</div>
            </div>

                <div class="grid grid-cols-2 grid-gap">
                  <submit-button @click="setStoreCollect" :defaultText="copy.collectCTA" :loading="storeCollectLoading" :disabled="!payNowSelected || deliveryLoading" :class="deliveryLoading ? '!opacity-50 !hover:opacity-50' : null" />

                  <submit-button @click="setDelivery" :defaultText="copy.deliveryCTA" :loading="deliveryLoading" :disabled="!payNowSelected || storeCollectLoading" :class="storeCollectLoading ? '!opacity-50 !hover:opacity-50' : null" />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, watch, onMounted, inject } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";
import SubmitButton from "../Components/SubmitButton.vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  copy: {
    type: Object,
    required: true,
  }
});

const axios = inject("axios");
const emit = defineEmits(["close", "collect", "delivery"]);
const initialLoad = ref(true);
const cartIsFinanceable = ref(false);

const payByCardLoading = ref(false);
const payByFinanceLoading = ref(false);
const payNowSelected = ref(false);

const deliverySelected = ref(false);
const deliveryLoading = ref(false);
const storeCollectSelected = ref(false);
const storeCollectLoading = ref(false);
const isOpen = ref(false);

const errorMessage = ref(null);
const errorMessageDetail = ref(null);

watch(
  () => props.show,
  (value) => {
    setIsOpen(value);
  }
);

const setIsOpen = (value) => {
  isOpen.value = value;
  payByCardLoading.value = false;
  payByFinanceLoading.value = false;
  payNowSelected.value = false;
  storeCollectSelected.value = false;
  storeCollectLoading.value = false;
  deliverySelected.value = false;
  deliveryLoading.value = false;
  initialLoad.value = true;
  errorMessage.value = null;
  errorMessageDetail.value = null;
  if (value) {
    progressCheckout();
  }
};

const progressCheckout = () => {
  axios
    .post(
      "/",
      {
        action: "jamiesoncarry/cart/checkout",
        CRAFT_CSRF_TOKEN: window.csrfTokenValue,
        initialLoad: initialLoad.value,
        deliverySelected: deliverySelected.value,
        storeCollectSelected: storeCollectSelected.value,
        cancelUrl: window.location.href,
      },
      { headers: { "content-type": "application/x-www-form-urlencoded" } }
    )
    .then((response) => {
      cartIsFinanceable.value = false;
      if (response.data.financeable === true) {
        cartIsFinanceable.value = true;
        return;
      }
      if (response.data.url) {
        window.location.href = response.data.url;
        return;
      }
      payNowSelected.value = true;
    })
    .catch(function (error) {
      if (error.response) {
        errorMessage.value = error.response.data.message;
        errorMessageDetail.value = Object.values(error.response.data.errors)?.join("\n");
      } else if (error.request) {
        errorMessage.value = "Something went wrong. Please try again.";
        errorMessageDetail.value = null;
      } else {
        errorMessage.value = "Something went wrong. Please try again.";
        errorMessageDetail.value = error.message;
      }

      stripeStarted.value = false;
    });
};

const setPayByFinance = () => {
  payByFinanceLoading.value = true;
  setTimeout(() => {
    window.location.href = "/cart/finance";
  }, 2000);
};

const setPayByCard = () => {
  if (payByFinanceLoading.value) {
    return;
  }
  payByCardLoading.value = true;
  setTimeout(() => {
    payNowSelected.value = true;
    payByCardLoading.value = false;
  }, 2000);
};

const setStoreCollect = () => {
  if (!payNowSelected.value) {
    return;
  }
  initialLoad.value = false;
  storeCollectLoading.value = true;
  storeCollectSelected.value = true;
  progressCheckout();
};

const setDelivery = () => {
  if (!payNowSelected.value || storeCollectLoading.value) {
    return;
  }
  initialLoad.value = false;
  deliveryLoading.value = true;
  deliverySelected.value = true;
  progressCheckout();
};
</script>
