<template>
  <Popover class="flow-root text-sm z-20" @keyup.esc="showing = false">
    <div v-if="showing" class="fixed inset-0 bg-black opacity-30 z-10" @click="showing = false"></div>

    <button class="group -m-2 flex items-center p-2 absolute top-[20px] right-[20px]" @click="showing = !showing">
      <ShoppingBagIcon class="h-6 w-6 flex-shrink-0" aria-hidden="true" />
      <span class="ml-2 font-sans min-w-[6.7px]">{{ qty ?? " " }}</span>
      <span class="sr-only">items in cart, view bag</span>
    </button>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-if="showing">
        <PopoverPanel static @close="showing = false" class="absolute inset-x-0 top-[54px] mt-px bg-white pb-4 shadow-lg sm:px-2 z-30 lg:left-auto lg:right-[20px] lg:w-80 lg:rounded-lg">
          <!--   lg:top-full lg:-mr-1.5 lg:mt-3   -->
          <h2 class="sr-only">Shopping Cart</h2>
          <div class="mx-auto max-w-2xl px-[20px]" v-if="lineItems.length">
            <ul role="list" class="divide-y divide-gray-200 list-none">
              <li v-for="lineItem in lineItems" :key="lineItem.id" class="py-6">
                <SmallCartLineItem :lineItem="lineItem" :saleActive="saleActive" @remove="remove" />
              </li>
            </ul>

            <div class="mb-4 text-red" v-if="errorMessage">
              <span class="font-bold">{{ errorMessage }}</span>
              <div class="" v-if="errorMessageDetail">{{ errorMessageDetail }}</div>
            </div>
            <submit-button @clicked="checkout" :defaultText="'Checkout'" :loadingText="'Please wait...'" :loading="stripeStarted" />

            <p class="mt-6 text-center">
              <a href="/cart" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">Make changes / View cart</a>
            </p>
          </div>

          <div class="mx-auto max-w-2xl py-36 px-4" v-else>
            <p class="text-center mb-0">Your cart is empty.</p>
          </div>


        </PopoverPanel>


      </div>
    </transition>
  </Popover>


</template>

<script setup>
import SmallCartLineItem from "./SmallCartLineItem.vue";
import SubmitButton from "../Components/SubmitButton.vue";
import { ShoppingBagIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverPanel } from "@headlessui/vue";
import { inject, ref, watch } from "vue";

const axios = inject("axios");
const qty = ref(null);
const lineItems = ref([]);
const showing = ref(false);
const props = defineProps({
  lastUpdateTimestamp: {
    type: Number,
    required: true,
  },
  saleActive: {
    type: Boolean,
    required: true,
  },
});

const stripeStarted = ref(false);
const errorMessage = ref(null);
const errorMessageDetail = ref(null);
const emits = defineEmits(["update-timestamp", "checkout"]);


watch(
  props,
  (oldvalue, newvalue) => {
    // console.log(oldvalue,newvalue);
    if (newvalue && !showing.value) {
      showing.value = true;
      stripeStarted.value = false;
    }
    axios
      .get("/", {
        params: {
          action: "commerce/cart/get-cart",
        },
      })
      .then((response) => {
        // TODO: need to check response and display an error to the user if there's an issue
        lineItems.value = response.data.cart.lineItems;
        qty.value = response.data.cart.totalQty;
        stripeStarted.value = false;
        // console.log(response.data.cart)
      })
      .catch(function (error) {
        console.log(error);
        // TODO: need to display an error to the user
        window.bugsnag.notify(error);
      });

    // console.log('lastUpdateTimestamp changed');
  },
  { immediate: true }
);

function remove(id) {
  axios
    .post(
      "/",
      {
        action: "commerce/cart/update-cart",
        lineItems: { [id]: { remove: 1 } },
        CRAFT_CSRF_TOKEN: window.csrfTokenValue,
      },
      { headers: { "content-type": "application/x-www-form-urlencoded" } }
    )
    .then((response) => {
      // TODO: need to check response and display an error to the user if there's an issue
      // console.log(response.data);
      emits("update-timestamp");
    })
    .catch(function (error) {
      if (error.response) {
        errorMessage.value = error.response.data.message;
        errorMessageDetail.value = Object.values(error.response.data.errors)?.join("\n");
      } else if (error.request) {
        errorMessage.value = "Something went wrong. Please try again.";
        errorMessageDetail.value = null;
      } else {
        errorMessage.value = "Something went wrong. Please try again.";
        errorMessageDetail.value = error.message;
      }
      // TODO: need to display an error to the user
      window.bugsnag.notify(error);
    });
}

function checkout() {
  stripeStarted.value = true;
  setTimeout(() => {
    showing.value = false;
    setTimeout(() => {
      stripeStarted.value = false;
    }, 3000);
    emits('checkout');
  }, 1500);
}

</script>
